import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";

import { Container } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FursanRoutes } from "../../routes/Routes";
import { Spacer } from "../Section/spacer";
import { NavigationContacts } from "./navigation-contacts";

interface Props {
	children: ReactElement;
}

function HideOnScroll(props: Props) {
	const { children } = props;

	const trigger = useScrollTrigger({
		target: window,
	});

	return (
		<>
			<Collapse
				appear={false}
				in={!trigger}
				easing={"cubic-bezier(0.42, 0, 0, 1)"}
			>
				{children}
			</Collapse>
		</>
	);
}

const drawerWidth = 240;

export function NavigationBar() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		const animateOnScrollObserver = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("animateOnScrollTriggered");
					}
				});
			},
			{ threshold: 0.5 }
		);
		const onScrollables = document.querySelectorAll(".animateOnScroll");
		onScrollables.forEach((section) =>
			animateOnScrollObserver.observe(section)
		);

		const animateOnScreenObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("onScreen");
				} else {
					entry.target.classList.remove("onScreen");
				}
			});
		});
		const onScreenables = document.querySelectorAll(".animateOnScreen");
		onScreenables.forEach((section) =>
			animateOnScreenObserver.observe(section)
		);

		let video = document.querySelector("video");
		if (video) {
			let videoObserver = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							video?.play();
							console.log("played");
						} else {
							video?.pause();
							console.log("paused");
						}
					});
				}
			);
			videoObserver.observe(video);
		}
	}, [pathname]);

	const trigger = useScrollTrigger({
		target: window,
	});
	const navigate = useNavigate();

	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	const drawer = (
		<Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
			<Box
				onClick={() => navigate("/")}
				component={"img"}
				sx={{ padding: "20px 40px", cursor: "pointer", }}
				src='/assets/images/Fursan-Logo copy.svg'
			></Box>
			<Divider />
			<List>
				{FursanRoutes.map((route) => (
					<ListItem key={route.name} disablePadding>
						<ListItemButton
							sx={{ textAlign: "center" }}
							onClick={() => {
								navigate(route.path);
							}}
						>
							<ListItemText primary={route.name} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);

	const container =
		window !== undefined ? () => window.document.body : undefined;

	return (
		<Fragment>
			<CssBaseline />

			<Box sx={{ display: "flex" }}>
				<Fragment>
					<CssBaseline />

					<AppBar
						component='nav'
						sx={{
							display: "block",
							backdropFilter: "blur(3px)",
							bgcolor: "#a74b00c7",
							// background:
							// 	"conic-gradient(at right top, rgb(137, 166, 195), rgba(0, 0, 0,96%))",
							background:
								"conic-gradient(at 0% 0%,#e84e0eeb 10%, #000000 141%)",
							// background:
							// 	"conic-gradient(at right top, rgb(137, 166, 195), #e84e0ee3)",
						}}
					>
						<HideOnScroll>
							<NavigationContacts />
						</HideOnScroll>
						<Toolbar
							component={Container}
							maxWidth='lg'
							sx={{
								minHeight: !trigger
									? "none"
									: "50px !important",
								transition:
									"min-height .5s cubic-bezier(0.42, 0, 0, 1)",
							}}
						>
							{/* <IconButton
								color='inherit'
								aria-label='open drawer'
								edge='start'
								onClick={handleDrawerToggle}
								sx={{ mr: 2, display: { sm: "none" } }}
							>
								<MenuIcon />
							</IconButton> */}
							{/* <Typography
								variant='h5'
								component='div'
								sx={{
									flexGrow: 1,
									display: {
										textAlign: "left",
									},
								}}
							>
								Fursan */}
							{/* </Typography> */}
							<Box
								sx={{
									display: "flex",
									height: "20px",
									flexGrow: 1,
									// justifyContent: {
									// 	xs: "center",
									// 	md: "flex-start",
									// },
									justifyContent: "center !important"

								}}
							>
								<Box
									onClick={() => navigate("/")}
									sx={{
										cursor: "pointer",

										height: "100%",
										filter: "brightness(100)",
										// display: "flex",
									}}
									component={"img"}
									src='/assets/images/Fursan-Logo.svg'
								/>

								{/* <Spacer size={32.5}></Spacer> */}
							</Box>
							<Box
								sx={{
									display: {
										xs: "none",
										sm: "block",
									},
								}}
							>
								{FursanRoutes.map((route) => (
									<Button
										key={route.name}
										onClick={() => {
											navigate(route.path);
										}}
										sx={{
											color: "#fff",
											textTransform: "capitalize",
										}}
									>
										{route.name}
									</Button>
								))}
							</Box>
						</Toolbar>
					</AppBar>
					{/* </HideOnScroll> */}
					<Toolbar />
				</Fragment>

				<Box component='nav'>
					<Drawer
						container={container}
						variant='temporary'
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: "block", sm: "none" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
						}}
					>
						{drawer}
					</Drawer>
				</Box>
			</Box>
		</Fragment>
	);
}
