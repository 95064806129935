
import {
    Box,
} from "@mui/material";
import { useState } from "react";


import { IPost } from "./Post";
import { StoryBody } from "./StoryBody";
import { StoryDivider } from "./StoryDivider";
import { StoryDrawer } from "./StoryDrawer";
import { DrawerType } from "./constants";


export function StoryItem({ direction, post ,setPosts,setReload, is_admin }: { post: IPost,setPosts:CallableFunction,setReload:CallableFunction, direction: string, is_admin:boolean }) {

    const [openState, setOpenState] = useState(false);

    const handleDrawerToggle = () => {
        setOpenState((prevState) => !prevState);
    };

    return (
        <>
            <Box
                sx={{
                    minHeight: "150px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <StoryBody
                    is_admin = {is_admin}
                    post={post}
                    visable={direction == "left"}
                    direction="left"
                    handleDrawerToggle={handleDrawerToggle}
                />

                <StoryDivider />

                <StoryBody
                    is_admin = {is_admin}
                    post={post}
                    visable={direction == "right"}
                    direction="right"
                    handleDrawerToggle={handleDrawerToggle}

                />
            </Box>
            <StoryDrawer setReload = {setReload} setPosts = {setPosts} drawerType={DrawerType.UPDATE} post={post} handleDrawerToggle={handleDrawerToggle} openState={openState} />


        </>
    );
}