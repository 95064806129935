import { Box, createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/footer/footer";
import { NavigationBar } from "./components/navigation/navigation-bar";

import { FursanRoutes } from "./routes/Routes";
import { LoginPage } from "./pages/admin/login";
import { NewsPage } from "./pages/news/news-page";

const theme = createTheme({
	palette: {
		primary: {
			main: "#e84e0e",
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Box
				className='App'
				sx={{
					bgcolor: "white",
					"& .MuiButton-contained": {
						background:
							"conic-gradient(at 0% 0%,#e84e0eeb 10%, #000000 141%)",
					},
					"& .animateOnScroll.fademe": {
						opacity: "0%",
						position: "relative",
						top: "30px",
						transition:
							"top 1s ease-in-out, opacity 1s ease-in-out",

						"&.animateOnScrollTriggered": {
							opacity: "100%",
							top: "0px",
						},
					},
				}}
			>
				<Router>
					<NavigationBar />
					<Routes>
						{FursanRoutes.map((route) => {
							return (
								<Route
									key={route.name}
									path={route.path}
									element={route.element}
								/>
							);
						})}	


						{/* <Route
							key={"Login Page"}
							path={"/admin/login"}
							element={<LoginPage/>}
						/> */}

						<Route
							key={"Fursan - Achievements"}
							path={"/admin/Achievements"}
							element={<NewsPage is_admin={true}/>}
						/>

						<Route
							key={"Fursan - Achievements"}
							path={"*"}
							element={<NewsPage is_admin={false}/>}
						/>

					</Routes>
					<Footer />
				</Router>
			</Box>
		</ThemeProvider>
	);
}

export default App;
