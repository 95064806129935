import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GoogleMaps } from "../../pages/contacts/map";
import { FursanRoutes } from "../../routes/Routes";
import { Column } from "../Section/column";
import { Divider } from "../Section/divider";
import { Row } from "../Section/row";
import { Section } from "../Section/section";
import { Spacer } from "../Section/spacer";

export function Footer() {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				background:
					"conic-gradient(from 90deg at 50% 0%, #111, 50%, #222, #111)",
				width: "100%",
				// padding: "30px",
				minHeight: "600px",
				color: "white",
			}}
		>
			<Section>
				<Row>
					<Column
						className='animateOnScroll fademe'
						sx={{
							// justifyContent: "flex-start",
							pb:"30px"
						}}
					>
						<>

						{FursanRoutes.length !=0 ?
							<>
							<Row
								sx={{
									justifyContent: {
										xs: "center",
										md: "flex-start",
									},
								}}
							>
								<Typography
									variant='h3'
									sx={{
										textAlign: {
											xs: "center",
											md: "unset",
										},
									}}
								>
									Navigation
								</Typography>
							</Row>


							<Divider
								sx={{
									m: { xs: "auto", md: "auto 0" },
									width: "60%",
								}}
							/>

							</>
							:null}
							{FursanRoutes.map((route) => (
								<Row
									key={route.name}
									sx={{
										justifyContent: {
											xs: "center",
											md: "flex-start",
										},
									}}
								>
									<Link
										component='button'
										variant='h5'
										onClick={() => {
											navigate(route.path);
										}}
										sx={{
											color: "#fff",
											textTransform: "capitalize",
											textDecoration: "none",

											userSelect: "text",
										}}
									>
										{route.name}
									</Link>
								</Row>
							))}
							<Spacer h={0} v={30} />

							<Row
								sx={{
									justifyContent: {
										xs: "center",
										md: "flex-start",
									},
								}}
							>
								<Typography
									variant='h3'
									sx={{
										textAlign: {
											xs: "center",
											md: "unset",
										},
									}}
								>
									Contacts
								</Typography>
							</Row>
							<Divider
								sx={{
									m: { xs: "10px auto", md: "10px 0" },
									width: "60%",
								}}
							/>
							<Row
								sx={{
									justifyContent: {
										xs: "center",
										md: "flex-start",
									},
								}}
							>
								<Button
									href={"mailto:info@fursan-med.com"}
									sx={{
										color: "#fff",
										fontSize: "10px",
										whiteSpace: "nowrap",

										userSelect: "text",
									}}
									variant='text'
									startIcon={<EmailIcon />}
								>
									info@fursan-med.com
								</Button>
							</Row>
							<Row
								sx={{
									justifyContent: {
										xs: "center",
										md: "flex-start",
									},
								}}
							>
								<Button
									href={"tel:+962 6 585 7 500"}
									sx={{
										color: "#fff",
										fontSize: "10px",
										whiteSpace: "nowrap",
										userSelect: "text",
									}}
									variant='text'
									startIcon={<PhoneIcon />}
								>
									+962 6 585 7 500
								</Button>
							</Row>
							<Row
								sx={{
									justifyContent: {
										xs: "center",
										md: "flex-start",
									},
								}}
							>
								<Button
									sx={{
										color: "#fff",
										fontSize: "10px",
										whiteSpace: "nowrap",
										userSelect: "text",
									}}
									variant='text'
									startIcon={<LocationOnIcon />}
								>
									P.O.Box 541303 Amman 11937 Jordan
								</Button>
							</Row>
						</>
					</Column>
				</Row>
				<GoogleMaps></GoogleMaps>
			</Section>
			<Box
				sx={{
					background:
						"conic-gradient(at 0% 0%,#e84e0eeb 10%, #000000 141%)",
				}}
			>
				All rights reserved © Fursan-Med 2023
			</Box>
		</Box>
	);
}
