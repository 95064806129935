import { Box, Button, Container, Toolbar } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
export function NavigationContacts() {
	return (
		<Box
			sx={{
				background: "conic-gradient(at top right, slategray, white)",
			}}
		>
			<Toolbar
				component={Container}
				maxWidth='lg'
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					minHeight: "0px !important",
				}}
			>
				<Box></Box>
				<Box
					sx={{
						display: "flex",
					}}
				>
					<Button
						href={"mailto:info@fursan-med.com"}
						sx={{
							color: "#767676",
							fontSize: "10px",
							whiteSpace: "nowrap",
						}}
						variant='text'
						startIcon={<EmailIcon />}
					>
						info@fursan-med.com
					</Button>
					<Button
						href={"tel:+962 6 585 7 500"}
						sx={{
							color: "#767676",
							fontSize: "10px",
							whiteSpace: "nowrap",
						}}
						variant='text'
						startIcon={<PhoneIcon />}
					>
						+962 6 585 7 500
					</Button>
					{/* <Button
					sx={{
						color: "#767676",
						fontSize: "10px",
						whiteSpace: "nowrap",
					}}
					variant='text'
					startIcon={<LocationOnIcon />}
				>
					P.O.Box 541303 Amman 11937 Jordan
				</Button> */}
				</Box>
			</Toolbar>
		</Box>
	);
}
