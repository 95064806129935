import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Container,
	Fab,
	IconButton,
	ListItemIcon,
	Paper,
	Skeleton,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Row } from "../../components/Section/row";

import EmailIcon from "@mui/icons-material/Email";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import Collapse from "@mui/material/Collapse";
import { HeaderText } from "../../components/HeaderText/HeaderText";
import { Divider } from "../../components/Section/divider";
import { Image } from "../../interfaces/ImageSlider";
import { AddIcCallOutlined, Edit, ExitToApp,Close, Delete, Save, Add } from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import { Column } from "../../components/Section/column";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Spacer } from "../../components/Section/spacer";
import axios from "axios";
import { IPost , Post} from "./Post";
import { StoryYear } from "./StoryYear";
import { StoryItem } from "./StoryItem";
import { StoryDrawer } from "./StoryDrawer";
import { DrawerType, StoryType } from "./constants";

var admin = true;







export function NewsPage({ is_admin = false }: { is_admin: boolean }) {
	useEffect(() => {
		document.title = "Fursan - Achievements";
	}, []);

	const [posts, setPosts] = useState<Array<Post>>([]);
	const [reload, setReload] = useState(0);

	useEffect(() => {
		const animateOnScrollObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("animateOnScrollTriggered");
				}
			});
		});
		const onScrollables = document.querySelectorAll(".animateOnScroll");
		onScrollables.forEach((section) =>
			animateOnScrollObserver.observe(section)
		);
	}, [posts]);

	let lastYear: number = -1;

	useEffect(()  => {
		
			 axios.post("https://fursan.rimawi.me/api/timeline/get",{}).then((response)=>{
				console.log(response.data) 
			 	setPosts(response.data.map((post:any)=>new Post({...post})));

			 })
		
	
		console.log(posts)
	},[reload])

	const [openState, setOpenState] = useState(false);

    const handleDrawerToggle = () => {
        setOpenState((prevState) => !prevState);
    };

	useEffect(()=>{},[posts])

	return (
		<Box
			sx={{
				background:
					"conic-gradient(at 0% 100%,  rgba(0, 0, 0, 30%) -15%,white 30%)",
			}}
		>
			{is_admin ?

				<>
					<Fab
						sx={{ position: "fixed", bottom: "25px", right: "25px" }}
						color="primary"
						aria-label="add"
						onClick={handleDrawerToggle}
					>
						<Add />
					</Fab>

					<StoryDrawer setReload = {setReload} setPosts = {setPosts} drawerType={DrawerType.NEW} openState = {openState} handleDrawerToggle = {handleDrawerToggle} post={new Post({date:new Date(),type:StoryType.NORMAL })} />
				</>
				: null}
			<Container>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pt: " 100px",
					}}
				>
					<HeaderText
						title="Our events"
						phrases={[
							"Together towards knights horizon",
							"Al Fursan For Medical Supplies",
						]}
					/>
				</Box>
				<Box sx={{ py: "40px" }}>
					{posts.map((item, index) => {
						if (item.date.getFullYear() !== lastYear) {
							lastYear = item.date.getFullYear();

							return [
								<StoryYear year={lastYear} />,
								<StoryItem
								setPosts= {setPosts}
									is_admin = {is_admin}
									setReload = {setReload}
									post={item}
									direction={index % 2 ? "left" : "right"}
								/>,
							];
						} else {
							return (
								<StoryItem
								is_admin = {is_admin}
								setPosts= {setPosts}
								setReload = {setReload}
									post={item}
									direction={index % 2 ? "left" : "right"}
								/>
							);
						}
					})}

					<StoryYear year={lastYear - 1} />
				</Box>
			</Container>
		</Box>
	);
}
