import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Divider } from "../../components/Section/divider";
import { Column } from "../Section/column";
export function HeaderText({
	title,
	phrases,
}: {
	title: string;
	phrases: Array<string>;
}) {
	const [index, setIndex] = useState<number>(0);
	const [show, setShow] = useState<Boolean>(false);

	useEffect(() => {
		setShow(true);
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setShow(false);

			setTimeout(() => {
				setIndex((index + 1) % phrases.length);
				setShow(true);
			}, 1300);
		}, 5000);
		return () => {
			clearInterval(interval);
		};
	});
	return (
		<Column
			sx={{
				alignItems: { md: "flex-start", xs: "center" },
				height: "100%",
			}}
		>
			<Typography
				variant='h3'
				sx={{
					fontWeight: "bold",
					textAlign: { xs: "center", md: "unset" },
				}}
			>
				{title}
			</Typography>

			<Typography
				variant='h2'
				className={show ? "show" : ""}
				sx={{
					fontWeight: "bold",
					width: "100%",
					overflow: "hidden",
					opacity: "0%",
					position: "relative",
					minHeight: "150px",
					display: "flex",
					justifyContent: { xs: "center", md: "unset" },
					textAlign: { xs: "center", md: "unset" },
					top: "10px",
					transition: "all 1.3s cubic-bezier(0.4, 0, 0.2, 1)",
					"&.show": {
						// minHeight: "100%",
						top: "0px",
						opacity: "100%",
					},

					"& span ": {
						background:
							" conic-gradient(at 8% 7%,#e84e0eeb 10%, #000000 141%)",
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "transparent",
					},
				}}
			>
				<span>{phrases[index]}</span>
			</Typography>

			<Box
				// className='animateOnScroll fademe'
				sx={{
					display: "flex",
					flexWrap: "wrap",
				}}
			>
				<Button
					href={"mailto:info@fursan-med.com"}
					sx={{
						color: "#767676",
						fontSize: "10px",
						whiteSpace: "nowrap",
					}}
					variant='text'
					startIcon={<EmailIcon />}
				>
					info@fursan-med.com
				</Button>
				<Button
					href={"tel:+962 6 585 7 500"}
					sx={{
						color: "#767676",
						fontSize: "10px",
						whiteSpace: "nowrap",
					}}
					variant='text'
					startIcon={<PhoneIcon />}
				>
					+962 6 585 7 500
				</Button>
				<Button
					sx={{
						color: "#767676",
						fontSize: "10px",
						whiteSpace: "nowrap",
					}}
					variant='text'
					startIcon={<LocationOnIcon />}
				>
					P.O.Box 541303 Amman 11937 Jordan
				</Button>
			</Box>
			<Divider />
		</Column>
	);
}
