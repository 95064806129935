import {
	Box,
	IconButton,
	Typography,
} from "@mui/material";
import { Row } from "../../components/Section/row";


import { Edit } from "@mui/icons-material";
import { IPost,Months } from "./Post";
import { StoryItemArrow } from "./StoryItemArrow";
import { StoryType } from "./constants";

export function StoryBody({
	post,
	direction = "none",
	visable = true,
	handleDrawerToggle = ()=>{},
    is_admin = false,
}: {
	post: IPost;
	direction?: string;
	visable?: boolean;
	handleDrawerToggle?: CallableFunction;
    is_admin?:boolean;

}) {
	return (
		<Box
			className="animateOnScroll fademe"
			sx={{
                whiteSpace: "break-spaces",
				width: "100%",
				my: "20px",
				px: "10px",
				display: "flex",
				visibility: visable ? "visable" : "hidden",
				justifyContent:
					direction == "right" ? "flex-start" : "flex-end",
			}}
		>
			<StoryItemArrow type = {post.type} direction={direction} isAt="right" />
			<Box
				sx={{
					width: "100%",
					background: post.type == StoryType.NORMAL ?
						"conic-gradient(at 0% 0%,#e84e0e 10%, #000000 141%)": "conic-gradient(at 0% 0%,#818181 10%, #000000 141%)",
					borderRadius: "10px",
					color: "white",
					padding: "20px",
					zIndex: "2",
				}}
			>
				<Row>

				<Typography variant="h5" align="left">
					{/* {Months[post.date.getMonth()]} */}
					{post.brief}
				</Typography>
                {is_admin?
				<IconButton sx = {{color:"white"}} onClick={() => handleDrawerToggle()} >
					<Edit></Edit>
				</IconButton >
                :<></>}
				</Row>

				{/* <Typography variant = "subtitle2" align="left">
					{Months[post.date.getMonth()]}
					</Typography> */}
                <hr />
				<Typography  variant = "body1"  align="left">{post.description}</Typography>
			</Box>
			<StoryItemArrow type = {post.type} direction={direction} isAt="left" />
		</Box>
	);
}

