import { AboutUsPage } from "../pages/aboutus/about-us-page";
import { ClientsPage } from "../pages/clients/clients-page";
import { ContactUsPage } from "../pages/contacts/contact-us-page";
import { HomePage } from "../pages/home/home-page";
import { NewsPage } from "../pages/news/news-page";
import { PartnersPage } from "../pages/partners/partners-page";
import { ServicesPage } from "../pages/services/ServicesPage";

interface Route {
	name: string;
	path: string;
	element: JSX.Element | any;
}

export const FursanRoutes: Array<Route> = [
	// { name: "Home", path: "/",  element: <NewsPage is_admin={false}/> /*element: <HomePage />*/ },
	// { name: "Services", path: "/services", element: <ServicesPage /> },
	// { name: "Partners", path: "/partners", element: <PartnersPage /> },
	// { name: "Clients", path: "/clients", element: <ClientsPage /> },
	// { name: "News", path: "/news", element: <NewsPage is_admin={false}/> },
	// { name: "Contact Us", path: "/contact-us", element: <ContactUsPage /> },
	// { name: "About Us", path: "/about-us", element: <AboutUsPage /> },

];
